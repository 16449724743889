import { render, staticRenderFns } from "./schools-types-delete.vue?vue&type=template&id=43e49c5e"
import script from "./schools-types-delete.vue?vue&type=script&lang=js"
export * from "./schools-types-delete.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports